table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 80px;
    
  }
  
  th, td {
    border: 5px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  .search-container{
    display: flex;
    margin-top: 30px;
    position: absolute;
    right: 100px;
    gap: 10px;
  }
  .search{
    display: flex;
    border: 2px solid black;
    border-radius: 10px;
    font-size: 15px;
  }
  .search-button{
    background:transparent;
    border: none;
    font-size: 25px;
  }
 .search-button:hover{
    color: brown;
 }