.print-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.print-table {
    border-collapse: collapse;
    width: 80%;
}

.print-table th,
.print-table td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
}

.print-total {
    margin-top: 20px;
    border: 1px solid black;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.total-row {
    font-weight: bold;
}
.bank-details{
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-left: 130px;
    margin-top: 30px;
}