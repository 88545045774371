.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background-size: cover;
    background-position: center;
  }
  
  .login-box {
    width: 100%;
    max-width: 400px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for the login box */
  }
  